export type Page = {
  id: number;
  name: string;
};

export type Info = {
  name: string;
  email: string;
  phone: string;
  photo: string;
  title: string;
  bio: string[];
  headline?: string;
  copyright?: {
    year: string;
  };
  resume_pdf?: string;
  socials: {
    id: string;
    img: string;
    link: string;
  }[];
};

export const PAGES = {
  RESUME: { id: 2, name: "resume" },
  // PROJECTS: { id: 3, name: "projects" },
  CONTACT: { id: 4, name: "contact" },
  ABOUT_ME: { id: 1, name: "about me" },
};

export const pages: Page[] = Object.values(PAGES).sort((a, b) =>
  a.id > b.id ? 1 : -1,
);

export enum CardNature {
  JOB = "JOB",
  STUDY = "STUDY",
  AWARD = "AWARD",
  SKILLS = "SKILLS",
}

export type Experience = {
  frm: string;
  to: string;
  title: string;
  place: string;
  location: string;
  description: string;
  tags: string[];
};

export type Award = {
  title: string;
  link: string;
  description: string;
  icon?: string;
};

export type Skill = {
  title: string;
  expertise: {
    years?: number;
    level?: string;
  };
  icon?: string;
};

export type Card = {
  nature: CardNature;
  values: Experience | Award | Skill[];
};

export type Section = {
  name: string;
  content: Card[];
};
