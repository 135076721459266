import React, { useState } from "react";
import MainBase from "../../component/main-base";

import "./index.scss";
import FormInput from "../../component/form-input";
import FormInputMulti from "../../component/form-input-multi";
import { postRequest } from "../../utils/request";

const MESSAGE_SENT_DURATION = 2000;

const Contact = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);

  const reset = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  const validResponse = () => {
    return [name, email, subject, message].every(
      (value: string) => value.length > 0,
    );
  };

  const sendMail = () => {
    return postRequest("/mail", {
      name,
      email,
      subject,
      message,
    });
  };

  const sendContact = (event: any) => {
    event.preventDefault();
    if (validResponse()) {
      setSendLoading(true);
      setMessageSent(true);
      sendMail().then((data) => {
        setSendLoading(false);
        reset();
        setTimeout(() => {
          setMessageSent(false);
        }, MESSAGE_SENT_DURATION);
      });
    }
  };

  return (
    <MainBase>
      <div className="contact_left"></div>
      <div className="contact_right"></div>
      <div className="contact_content">
        <form className="contact_form">
          <FormInput
            label="name"
            required={true}
            setValue={setName}
            value={name}
            disabled={sendLoading}
          />
          <FormInput
            label="email"
            required={true}
            setValue={setEmail}
            value={email}
            disabled={sendLoading}
          />
          <FormInput
            label="subject"
            required={true}
            setValue={setSubject}
            value={subject}
            disabled={sendLoading}
          />
          <FormInputMulti
            label="message"
            required={true}
            setValue={setMessage}
            value={message}
            disabled={sendLoading}
          />
          <div className="send_container">
            <button
              className="send"
              onClick={sendContact}
              disabled={!validResponse() || sendLoading}
            >
              send
            </button>
            {messageSent ? (
              <div className="message_sent">
                {sendLoading ? (
                  <div className="loading"></div>
                ) : (
                  <span className="message">Message sent!</span>
                )}
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </MainBase>
  );
};

export default Contact;
