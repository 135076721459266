import { createContext } from "react";

export type AppContextValue = {
  popupActive: boolean;
  setPopupActive: (value: boolean) => void;
  popUpType: any;
  setPopUpType: (children: any) => void;
  popUpResult: any;
  setPopUpResult: (result: any) => void;
};

const appContextValue: AppContextValue = {
  popupActive: false,
  setPopupActive: (value: boolean) => {},
  popUpType: 0,
  setPopUpType: () => {},
  popUpResult: null,
  setPopUpResult: (result: any) => {},
};

export const AppContext = createContext<AppContextValue>(appContextValue);
