import { HOST_API } from "../constant";

type RequestResponse = {
  success: boolean;
  content: any;
};

export const request = (path: string, method: string, body?: any) => {
  return new Promise<any>((resolve, reject) => {
    fetch(`${HOST_API}${path}`, {
      method: method,
      ...(body
        ? {
            body: JSON.stringify(body),
          }
        : {}),
    })
      .then((response) => response.json())
      .then((data: RequestResponse) => {
        if (data.success) {
          resolve(data.content ?? null);
        } else {
          resolve({});
        }
      })
      .catch((error) => reject(error));
  });
};

export const getRequest = (path: string) => {
  return request(path, "GET");
};

export const postRequest = (path: string, body: any) => {
  return request(path, "POST", body);
};
