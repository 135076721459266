import React, { useContext, useEffect, useState } from "react";
import FormInput from "../../form-input";

import "./index.scss";
import { AppContext, AppContextValue } from "../../../app-context";

export type QuickContactContent = {
  email: string;
  subject: string;
};

const QuickContactPopUp = () => {
  const appContext = useContext<AppContextValue>(AppContext);
  const [quickContactEmail, setQuickContactEmail] = useState<string>("");
  const [quickContactSubject, setQuickContactSubject] = useState<string>("");
  const [render, setRender] = useState<boolean>(false);

  const reset = () => {
    setQuickContactEmail("");
  };

  const validQuickContact = () => {
    return quickContactEmail.length > 0 && quickContactSubject.length > 0;
  };

  const sendQuickContact = () => {
    if (validQuickContact()) {
      appContext.setPopUpResult({
        email: quickContactEmail,
        subject: quickContactSubject,
      });
    }
  };

  useEffect(() => {
    if (!appContext.popupActive) {
      reset();
      setRender(false);
    } else {
      setTimeout(() => {
        setRender(true);
      }, 500);
    }
  }, [appContext.popupActive]);

  const getRender = () => {
    return (
      <div className="popup_quick_contact_email">
        <FormInput
          label="Email"
          required={true}
          setValue={setQuickContactEmail}
          value={quickContactEmail}
        />
        <FormInput
          label="Subject"
          required={true}
          setValue={setQuickContactSubject}
          value={quickContactSubject}
        />
        <button
          className="popup_quick_contact_email_send"
          onClick={sendQuickContact}
          disabled={!validQuickContact()}
        >
          Let me contact you
        </button>
      </div>
    );
  };

  return render ? getRender() : null;
};

export default QuickContactPopUp;
