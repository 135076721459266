import React, { useState } from "react";
import { AppContext } from "./app-context";
import PopUp, { PopUpTypes } from "./component/popup";
import App from "./App";

const AppContextComponent = () => {
  const [popupActive, setPopupActive] = useState<boolean>(false);
  const [popUpType, setPopUpType] = useState<PopUpTypes>(
    PopUpTypes.QuickContact,
  );
  const [popUpResult, setPopUpResult] = useState<any>();

  return (
    <>
      <AppContext.Provider
        value={{
          popupActive,
          setPopupActive,
          popUpType,
          setPopUpType,
          popUpResult,
          setPopUpResult,
        }}
      >
        <PopUp />
        <App />
      </AppContext.Provider>
    </>
  );
};

export default AppContextComponent;
