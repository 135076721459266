import React from "react";

import "./index.scss";

const FormInputMulti = ({
  label,
  placeholder,
  required,
  value,
  setValue,
  disabled,
}: {
  label: string;
  placeholder?: string;
  required: boolean;
  value: any;
  setValue: (value: any) => void;
  disabled?: boolean;
}) => {
  return (
    <div className="form_input">
      <div className="label">
        <span className="label_text">{label}</span>{" "}
        {required ? <span className="label_required">*</span> : null}
      </div>
      <textarea
        placeholder={placeholder ?? ""}
        onChange={(event) => setValue(event.target.value)}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

export default FormInputMulti;
