import React, { useContext, useEffect, useState } from "react";
import { AppContext, AppContextValue } from "../../app-context";

import "./index.scss";

import MainBase from "../../component/main-base";
import { Page, Info, PAGES } from "../../commons";
import { PopUpTypes } from "../../component/popup";
import { postRequest } from "../../utils/request";
import { QuickContactContent } from "../../component/popup/quick-contact";

const AboutMe = ({
  info,
  selectPage,
}: {
  info: Info;
  selectPage: (pg: Page) => void;
}) => {
  const appContext = useContext<AppContextValue>(AppContext);

  const sendQuickContact = (result: QuickContactContent) => {
    postRequest("/mail", {
      email: result.email,
      subject: result.subject,
    });
    appContext.setPopupActive(false);
  };

  const openQuickContact = () => {
    appContext.setPopupActive(true);
    appContext.setPopUpType(PopUpTypes.QuickContact);
  };

  useEffect(() => {
    if (appContext.popUpResult) {
      sendQuickContact(appContext.popUpResult);
    }
  }, [appContext.popUpResult]);

  const getActionsButtonsRender = () => {
    return (
      <div className="actions">
        <button onClick={() => selectPage(PAGES.RESUME)}>resume</button>
        {/* <button onClick={() => selectPage(PAGES.PROJECTS)}>projects</button> */}
        <button onClick={openQuickContact}>quick contact</button>
      </div>
    );
  };

  return (
    <MainBase>
      <div className="aboutme_page">
        <div className="about_left"></div>
        <div className="about_right"></div>
        <div className="about_content">
          <div className="card">
            <div className="picture">
              <img src={info.photo} alt="profile_photo" className="photo" />
            </div>
            <div className="name">
              <span className="text">{info.name}</span>
            </div>
            <div className="underline">
              <div className="line"></div>
            </div>
            <div className="title">
              <span className="title_text">{info.title}</span>
              {getActionsButtonsRender()}
            </div>
            <div className="socials">
              {info.socials.map((social) => (
                <a href={social.link} target="_blank" key={social.id}>
                  <img src={social.img} alt={social.id} />
                </a>
              ))}
            </div>
          </div>
          <div className="profile">
            <div className="welcome">Hello</div>
            <div className="headline">{info.headline}</div>
            {getActionsButtonsRender()}
            <div className="bio">
              {info.bio.map((txt) => (
                <p key={Math.random()}>{txt}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainBase>
  );
};

export default AboutMe;
