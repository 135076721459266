import React, { useContext, useEffect, useState } from "react";

import "./index.scss";
import { AppContext, AppContextValue } from "../../app-context";
import QuickContactPopUp from "./quick-contact";

export enum PopUpTypes {
  QuickContact = "QuickContact",
}

const POPUP_CLOSE_DELAY = 500;

const PopUp = () => {
  const appContext = useContext<AppContextValue>(AppContext);
  const [closing, setClosing] = useState<boolean>(false);
  const [firstTime, setFirstTime] = useState<boolean>(true);

  const getPopUp = () => {
    let result;
    switch (appContext.popUpType) {
      case PopUpTypes.QuickContact:
        result = <QuickContactPopUp />;
        break;
      default:
        result = null;
        break;
    }
    return result;
  };

  useEffect(() => {
    if (!appContext.popupActive && !firstTime) {
      setClosing(true);
      setTimeout(() => {
        setClosing(false);
      }, POPUP_CLOSE_DELAY);
    }
  }, [appContext.popupActive]);

  useEffect(() => {
    setTimeout(() => {
      setFirstTime(false);
    }, POPUP_CLOSE_DELAY);
  }, [firstTime]);

  return appContext.popupActive || closing ? (
    <>
      <div
        className="popup_screen"
        onClick={() => appContext.setPopupActive(false)}
      ></div>
      <div className={closing ? "closing_popup_window" : "popup_windows"}>
        <div className="popup_children">{getPopUp()}</div>
      </div>
    </>
  ) : null;
};

export default PopUp;
