import React, { useState } from "react";
import MainBase from "../../component/main-base";

import "./index.scss";
import {
  CardNature,
  Experience,
  Award,
  Skill,
  Card,
  Section,
  Info,
} from "../../commons";

const Resume = ({ info, sections }: { info: Info; sections: Section[] }) => {
  const getExperienceCardRender = (value: Experience) => {
    return (
      <div className="experience">
        <div className="info">
          <span className="info_period">
            {value.frm} - {value.to}
          </span>
          <span className="info_title">{value.title}</span>
          <span className="info_place">{value.place}</span>
          <span className="info_location">{value.location}</span>
        </div>
        <div className="description">{value.description}</div>
      </div>
    );
  };

  const getAwardCardRender = (value: Award) => {
    return (
      <div className="award">
        <img src={value.icon} alt="" className="award_image" />
        <div className="award_details">
          <div className="award_title">
            {value.title}
            {value.link ? (
              <>
                <span className="award_separator">-</span>
                <a href={value.link} target="_blank" className="award_source">
                  Source
                </a>
              </>
            ) : null}
          </div>
          <div className="award_description">{value.description}</div>
        </div>
      </div>
    );
  };

  const getSkillsCardRender = (value: Skill[]) => {
    return (
      <div className="skill_box">
        <div className="skills">
          {value.map((skill) => (
            <div className="skill">
              <img src={skill.icon} alt="" className="skill_icon" />
              <div className="skill_name">
                {skill.title}
                {skill.expertise.years ? (
                  <div className="skill_exp">{skill.expertise.years} years</div>
                ) : (
                  <div className="skill_exp">{skill.expertise.level}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getCardRender = (card: Card) => {
    let result;
    switch (card.nature) {
      case CardNature.JOB:
        result = getExperienceCardRender(card.values as Experience);
        break;
      case CardNature.STUDY:
        result = getExperienceCardRender(card.values as Experience);
        break;
      case CardNature.AWARD:
        result = getAwardCardRender(card.values as Award);
        break;
      case CardNature.SKILLS:
        result = getSkillsCardRender(card.values as Skill[]);
        break;
      default:
        result = null;
        break;
    }

    return result;
  };

  return (
    <MainBase>
      <div className="resume_left"></div>
      <div className="resume_right"></div>
      <div className="resume_content">
        {/* <div className="resume_page_title">
                    <div className="ball"></div>
                    <span>Resume</span>
                </div> */}
        <a
          href={info.resume_pdf}
          className="download_resume"
          download="resume.pdf"
          target="_blank"
        >
          Download PDF
        </a>
        <div className="sections">
          {sections.map((section) => (
            <div className="section">
              <div className="section_name">{section.name}</div>
              {section.content.map((card) => (
                <div className="section_card">{getCardRender(card)}</div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </MainBase>
  );
};

export default Resume;
